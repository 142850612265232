import React from "react";
import {
  // BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Home from "../pages/Home/Home";
import Tables from "../pages/Tables/Tables";
import Contact from "../pages/Contact/Contact";
import Menus from "../pages/menus/Menus";
import Navbar from "./navbar";
import { useSelector } from "react-redux";
import SideMenu from "./sidemenu/SideMenu";
import AppNavbar from "./sidemenu/AppNavbar";
// import { Container } from "@mui/material";
const Nav = () => {
  const isAuthenticated = useSelector(
    (state) => state.UserData.isAuthenticated
  );
  const language = useSelector((state) => state.Language.slctdLng);

  console.log("router of merchant");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: language.langRowRvrs,
        backgroundColor: "#FCFCFC",
      }}
    >
      {isAuthenticated && (
        <>
          <AppNavbar />
          <SideMenu />
        </>
      )}
      <div style={{ flex: 1, padding: 20 }}>
        <Routes style={{}}>
          <Route
            path="login"
            element={isAuthenticated ? <Navigate to="/merchant" /> : <Login />}
          />
          <Route
            path="register"
            element={
              isAuthenticated ? <Navigate to="/merchant" /> : <Register />
            }
          />
          <Route
            path="/"
            element={isAuthenticated ? <Home /> : <Navigate to="login" />}
          />
          <Route
            path="tables"
            element={
              isAuthenticated ? <Tables /> : <Navigate to="/merchant/login" />
            }
          />
          <Route
            path="contact"
            element={
              isAuthenticated ? <Contact /> : <Navigate to="/merchant/login" />
            }
          />
          <Route
            path="menus"
            element={
              isAuthenticated ? <Menus /> : <Navigate to="/merchant/login" />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Nav;
