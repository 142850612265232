import * as React from "react";
import MuiAvatar from "@mui/material/Avatar";
import MuiListItemAvatar from "@mui/material/ListItemAvatar";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListSubheader from "@mui/material/ListSubheader";
import Select, { selectClasses } from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Rst from "@mui/icons-material/Restaurant";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import { useSelector, useDispatch } from "react-redux";
import { ar } from "../../../lang/ar";
import { en } from "../../../lang/en";

export default function SelectContent() {
  const [company, setCompany] = React.useState("");
  const language = useSelector((state) => state.Language.slctdLng);
  const dispatch = useDispatch();
  const handleLanguageChange = (val) => {
    val === "ar"
      ? dispatch({ type: "CHANGE_LANG", payload: ar })
      : dispatch({ type: "CHANGE_LANG", payload: en });
  };
  const handleChange = (event) => {
    setCompany(event.target.value);
  };

  return (
    <Select
      labelId="company-select"
      id="company-simple-select"
      value={company}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Select company" }}
      fullWidth
      sx={{
        textAlign: language.langAlign,
        direction: language.langDir,
        maxHeight: 56,
        width: 215,
        "&.MuiList-root": {
          p: "8px",
        },
        [`& .${selectClasses.select}`]: {
          display: "flex",
          alignItems: "center",
          gap: "2px",
          pl: 1,
        },
      }}
    >
      {/* <ListSubheader sx={{ pt: 0, textAlign: "right" }}>
        Production
      </ListSubheader> */}
      <MenuItem
        sx={{
          textAlign: language.langAlign,
          direction: language.langDir,
        }}
        value=""
      >
        <ListItemIcon>
          <Rst />
        </ListItemIcon>
        <ListItemText primary="مطعم 2" secondary="الرئيسي" />
      </MenuItem>
      <MenuItem
        sx={{
          textAlign: language.langAlign,
          direction: language.langDir,
        }}
        value={10}
      >
        <ListItemIcon>
          <Rst />
        </ListItemIcon>
        <ListItemText primary="مطعم 1" secondary="فرع الخالدية" />
      </MenuItem>
      <MenuItem
        sx={{
          textAlign: language.langAlign,
          direction: language.langDir,
        }}
        value={20}
      >
        <ListItemIcon>
          <Rst />
        </ListItemIcon>
        <ListItemText primary="مطعم 3" secondary="السليمانية" />
      </MenuItem>
      <Divider sx={{ mx: -1 }} />
      <MenuItem
        sx={{
          textAlign: language.langAlign,
          direction: language.langDir,
        }}
        value={40}
      >
        <ListItemIcon>
          <AddRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="إضافة مطعم" />
      </MenuItem>
    </Select>
  );
}
