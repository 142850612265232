import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import TableBar from "@mui/icons-material/TableBar";
import SpaceDashboard from "@mui/icons-material/SpaceDashboard";
import RestaurantRounded from "@mui/icons-material/RestaurantRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { useSelector, useDispatch } from "react-redux";
import { ar } from "../../../lang/ar";
import { en } from "../../../lang/en";
import { useNavigate ,useLocation} from "react-router-dom";

export default function MenuContent() {
  const language = useSelector((state) => state.Language.slctdLng);
  const dispatch = useDispatch();
  const handleLanguageChange = (val) => {
    val === "ar"
      ? dispatch({ type: "CHANGE_LANG", payload: ar })
      : dispatch({ type: "CHANGE_LANG", payload: en });
  };
  const navigate = useNavigate();
  const location = useLocation();

  const mainListItems = [
    { text: language.home, navTo: "/merchant", icon: <HomeRoundedIcon /> },
    {
      text: language.Tables,
      navTo: "/merchant/tables",
      icon: <TableBar />,
    },
    {
      text: language.menusTxt,
      navTo: "/merchant/menus",
      icon: <SpaceDashboard />,
    },
    {
      text: language.Restaurants,
      navTo: "/merchant/contact",
      icon: <RestaurantRounded />,
    },
  ];

  const secondaryListItems = [
    {
      text: language.SettingsTxt,
      navTo: "/merchant",
      icon: <SettingsRoundedIcon />,
    },
    { text: language.aboutUs, navTo: "/merchant", icon: <InfoRoundedIcon /> },
  ];
  const NavigateTo = (Item) => {
    handleNavigation(Item.navTo);
  };
  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => NavigateTo(item)}
              sx={{
                textAlign: language.langCode == "ar" ? "right" : "left",
                direction: language.langCode == "ar" ? "rtl" : "ltr",
              }}
              selected={location.pathname == item.navTo}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                textAlign: language.langCode == "ar" ? "right" : "left",
                direction: language.langCode == "ar" ? "rtl" : "ltr",
              }}
              onClick={() => NavigateTo(item)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
