import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Box, Paper, Typography } from "@mui/material";
import GContainer from "../component/GContainer";
import { useSelector } from "react-redux";

const data = [
  { name: "January", sales: 3000 },
  { name: "February", sales: 2000 },
  { name: "March", sales: 4000 },
  { name: "April", sales: 5000 },
  { name: "May", sales: 7000 },
  { name: "June", sales: 6000 },
];

const Home = () => {
  const language = useSelector((state) => state.Language.slctdLng);

  return (
    <GContainer>
      <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 ,direction:language.langDir}}>
        <Paper elevation={3} sx={{ minWidth: "30%", mx: 1, my: 1,p:1 }}>
          <Typography variant="h5" align="center" gutterBottom>
            إحصائيات الزيارات
          </Typography>
          <Box sx={{ width: "100%", height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sales" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ minWidth: "30%", mx: 1, my: 1 ,p:1}}>
          <Typography variant="h5" align="center" gutterBottom>
            Monthly Sales Data
          </Typography>
          <Box sx={{ width: "100%", height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sales" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ minWidth: "30%", mx: 1, my: 1 ,p:1}}>
          <Typography variant="h5" align="center" gutterBottom>
            Monthly Sales Data
          </Typography>
          <Box sx={{ width: "100%", height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sales" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ minWidth: "30%", mx: 1, my: 1,p:1 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Monthly Sales Data
          </Typography>
          <Box sx={{ width: "100%", height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sales" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Paper>
      </Box>
    </GContainer>
  );
};

export default Home;
